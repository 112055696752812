:root {
    --main-bg-color: #2E82FF;
    --gray-bg-color: #888888;
    --avatar-bg-color: #505050;
    --font-family: 'Noto Sans', Arial, sans-serif;
}

a {
    color: #FFA500;
    text-decoration: none;
}

a:hover {
    color: #FFA500;
    text-decoration: underline;
}

.app-chatbot-container {
    margin: 0;
    display: flex;
    justify-content: center;
    position: fixed;
    right: 30px;
    bottom: 100px;
    z-index: 9999;
    box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
    border-radius: 5px;
    transition: opacity 300ms, visibility 300ms;
    font-family: var(--font-family);
}

.app-chatbot-container.hide {
    visibility: hidden;
    opacity: 0;
}

.app-chatbot-trigger {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 100;
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    background-image: url('https://cdn.jsdelivr.net/gh/Skylore/ai-chatbot-demo@1.2.1-wine/build/static/media/chat.webp');
    background-size: cover;
    outline: none;
    -webkit-filter: drop-shadow(0 0 13px rgba(91, 81, 81, 0.4));
    filter: drop-shadow(0 0 13px rgba(91, 81, 81, 0.4));
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
}

.react-chatbot-kit-chat-btn-send {
    background-color: var(--main-bg-color);
    transition: background-color 300ms;
}

.react-chatbot-kit-chat-btn-send[disabled] {
    background-color: var(--gray-bg-color);
}

.react-chatbot-kit-chat-bot-message {
    background-color: var(--main-bg-color);
    font-size: 12px!important;
}

.react-chatbot-kit-user-chat-message {
    font-size: 12px!important;
}

.react-chatbot-kit-chat-bot-message-arrow {
    border-right-color: var(--main-bg-color);
}

.react-chatbot-kit-user-avatar-container {
    background-color: var(--avatar-bg-color);
}

.react-chatbot-kit-chat-header,
.react-chatbot-kit-chat-input {
    font-family: var(--font-family);
}

.react-chatbot-kit-chat-input {
    font-size: 12px!important;
    border-radius: 0 0 0 5px;
    outline: none;
}

.react-chatbot-kit-chat-container {
    width: 320px;
    max-width: calc(100vw - 60px);
}

.react-chatbot-kit-chat-inner-container {
    height: 600px;
    max-height: calc(100dvh - 130px);
}

.react-chatbot-kit-chat-message-container {
    height: 520px;
    max-height: calc(100dvh - 210px);
}

.react-chatbot-kit-chat-bot-avatar-container img {
    width: 100%;
    display: block;
    border-radius: 50%;
}
